body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.float-right {
  float: right;
}
.column {
  float: left;
  width: 42%;
  margin: 0% 4%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
a:link {
  text-decoration: none;
}

.uploaderMessage{
  text-align: center; 
}

@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
}